import { render, staticRenderFns } from "./CreateEditAdminForm.vue?vue&type=template&id=58628227&"
import script from "./CreateEditAdminForm.vue?vue&type=script&lang=js&"
export * from "./CreateEditAdminForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports