<script>
import AdminForm from './create-edit-admin-form-components/AdminForm.vue'

export default {
  components: { AdminForm },
  props: {
    modeProp: {
      type: String,
      required: true,
      validator: (v) => ['CREATE', 'EDIT'].includes(v)
    },
    isLoadingProp: {
      type: Boolean,
      required: false,
      default: false
    },
    userLogonMasterProp: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    }
  },
  methods: {
    createAdminSubmittedHandler(event) {
      this.$emit('createAdminSubmitted', event)
    },
    editAdminSubmittedHandler(event) {
      this.$emit('editAdminSubmitted', event)
    }
  }
}
</script>

<template>
  <admin-form
    :modeProp="modeProp"
    :isLoadingProp="isLoadingProp"
    :userLogonMasterProp="userLogonMasterProp"
    @createAdminSubmitted="createAdminSubmittedHandler"
    @editAdminSubmitted="editAdminSubmittedHandler"
  ></admin-form>
</template>

<style></style>
