<script>
import { mapState } from 'vuex'

import authService from '@/services/auth/auth'
import { toastMixins } from '@/mixins'

import MenuBar from './components/MenuBar.vue'

import CreateEditAdminForm from '../components/CreateEditAdminForm.vue'

export default {
  mixins: [toastMixins],
  components: { MenuBar, CreateEditAdminForm },
  data() {
    return {
      errMsg: null,
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      defaultDelayMs: (state) => state.constants.defaultDelayMs
    })
  },
  methods: {
    async createAdminSubmittedHandler(event) {
      const { args } = event

      this.isLoading = true

      try {
        await authService.signupAdmin(args)
        this.mxDisplaySuccessMessage('สร้างแอดมินรายใหม่สำเร็จแล้ว')
        setTimeout(() => {
          this.$router.push({
            name: 'Admin.UserLogonMasters'
          })
        }, this.defaultDelayMs)
      } catch (err) {
        this.errMsg =
          err?.response?.data?.thMessage ||
          'เกิดข้อผิดพลาดในระบบสร้างแอดมินรายใหม่'
      }

      this.isLoading = false
    }
  }
}
</script>

<template>
  <b-container fluid="lg">
    <!-- แถบ menu ด้านบน -->
    <b-row class="d-flex justify-content-between">
      <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
        <page-title title="สร้างแอดมินรายใหม่"></page-title>
      </b-col>
      <b-col
        class="col-12 col-xl-auto py-3 d-flex justify-content-center justify-content-xl-end"
      >
        <menu-bar></menu-bar>
      </b-col>
    </b-row>

    <!-- create form -->
    <create-edit-admin-form
      :modeProp="'CREATE'"
      :isLoadingProp="isLoading"
      @createAdminSubmitted="createAdminSubmittedHandler"
    ></create-edit-admin-form>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-container>
</template>

<style></style>
