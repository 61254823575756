<script>
import _ from 'lodash'
import moment from 'moment'

import AppCard from '@/components/card/Card.vue'

export default {
  components: { AppCard },
  props: {
    modeProp: {
      type: String,
      required: true,
      validator: (v) => ['CREATE', 'EDIT'].includes(v)
    },
    isLoadingProp: {
      type: Boolean,
      required: false,
      default: false
    },
    userLogonMasterProp: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      keys: [
        'userId',
        'password',
        'passwordConfirm',
        'userName',
        'department',
        'jobTitle',
        'userExpiredAt'
      ],
      labelKey: {
        userId: 'ผู้ใช้งาน *',
        password: 'รหัสผ่าน *',
        passwordConfirm: 'ยืนยันรหัสผ่าน *',
        userName: 'ชื่อผู้ใช้งาน *',
        department: 'แผนก',
        jobTitle: 'ตำแหน่งงาน',
        userExpiredAt: 'ผู้ใช้หมดอายุ'
      },
      oldData: {},
      newData: {}
    }
  },
  watch: {
    userLogonMasterProp() {
      this.initialized()
    }
  },
  created() {
    this.initialized()
  },
  methods: {
    initialized() {
      switch (this.modeProp) {
        case 'CREATE': {
          const newDataArgs = {}
          this.keys.forEach((key) => {
            newDataArgs[key] = null
          })
          this.oldData = { ...newDataArgs }
          this.newData = { ...newDataArgs }
          break
        }
        case 'EDIT': {
          this.keys = this.keys.filter((k) => {
            return !['userId', 'password', 'passwordConfirm'].includes(k)
          })
          const oldDataArgs = {}
          this.keys.forEach((key) => {
            const value = this.userLogonMasterProp[key]
            if (
              _.isNil(value) ||
              (_.isString(value) && value.trim().length === 0)
            ) {
              oldDataArgs[key] = null
            } else if (_.isString(value)) {
              oldDataArgs[key] = value.trim()
            } else {
              oldDataArgs[key] = value
            }
          })
          if (!_.isNil(oldDataArgs.userExpiredAt)) {
            oldDataArgs.userExpiredAt = moment(oldDataArgs.userExpiredAt)
              .utcOffset('+0700')
              .format('YYYY-MM-DD')
          }
          this.oldData = { ...oldDataArgs }
          this.newData = { ...oldDataArgs }
          break
        }
        default:
          break
      }
    },
    shouldSaveButtonEnable() {
      switch (this.modeProp) {
        case 'CREATE': {
          return _.values(_.pick(this.newData, this.keys)).some(
            (v) => !_.isNil(v) && !(_.isString(v) && v.trim().length === 0)
          )
        }
        case 'EDIT': {
          const objectWithPickedKeys = _.pick(this.newData, this.keys)
          const objectKeys = _.keys(objectWithPickedKeys)
          return objectKeys.some((key) => {
            const newValue = this.newData[key]
            const oldValue = this.oldData[key]
            return newValue !== oldValue
          })
        }
        default:
          return false
      }
    },
    getNewDataArgs() {
      const newArgs = {}
      const isUndefinedNullOrEmptyString = (v) => {
        return _.isNil(v) || (_.isString(v) && v.trim().length === 0)
      }

      switch (this.modeProp) {
        case 'CREATE': {
          this.keys.forEach((key) => {
            const value = this.newData[key]
            if (isUndefinedNullOrEmptyString(value)) {
              newArgs[key] = null
            } else if (_.isString(value)) {
              newArgs[key] = value.trim()
            } else {
              newArgs[key] = value
            }
          })
          break
        }
        case 'EDIT': {
          this.keys.forEach((key) => {
            const newValue = this.newData[key]
            const oldValue = this.oldData[key]
            if (newValue !== oldValue) {
              if (isUndefinedNullOrEmptyString(newValue)) {
                newArgs[key] = null
              } else if (_.isString(newValue)) {
                newArgs[key] = newValue.trim()
              } else {
                newArgs[key] = newValue
              }
            }
          })
          break
        }
        default:
          break
      }

      if (!_.isNil(newArgs.userExpiredAt)) {
        newArgs.userExpiredAt = moment(newArgs.userExpiredAt).toISOString()
      }

      return newArgs
    },
    buttonClicked(type) {
      const body = this.getNewDataArgs()
      switch (type) {
        case 'CONFIRM':
          switch (this.modeProp) {
            case 'CREATE': {
              const args = {
                ...body,
                userLevel: 99.99,
                happysoftSignupCode: process.env.VUE_APP_SIGNUP_CODE,
                happysoftAdminSignupCode: process.env.VUE_APP_ADMIN_SIGNUP_CODE
              }
              this.$emit('createAdminSubmitted', { args })
              break
            }
            case 'EDIT': {
              this.$emit('editAdminSubmitted', { args: body })
              break
            }
            default:
              break
          }
          break
        case 'CANCEL': {
          this.$router.push({ name: 'Admin.UserLogonMasters' })
          break
        }
        default:
          break
      }
    }
  }
}
</script>

<template>
  <app-card>
    <b-row>
      <!-- title -->
      <b-col cols="12" class="font-weight-bold text-lg">
        <div>
          <span> ข้อมูลของแอดมินรายใหม่ </span>
        </div>
      </b-col>

      <!-- contents -->
      <b-col cols="12">
        <b-row
          v-for="(key, index) in keys"
          :key="key"
          :class="`m-0 p-0 mb-2 ${index === 0 ? 'mt-3' : ''}`"
          align-v="center"
        >
          <b-col cols="3" class="m-0 p-0">
            <div class="d-flex align-items-center">
              <span>{{ labelKey[key] }}</span>
            </div>
          </b-col>
          <b-col cols="9" class="m-0 p-0">
            <div v-if="['userExpiredAt'].includes(key)">
              <b-form-datepicker
                id="example-datepicker"
                v-model="newData[key]"
                locale="th"
                :disabled="isLoadingProp"
              ></b-form-datepicker>
            </div>
            <div v-else>
              <b-form-input
                v-model="newData[key]"
                :type="
                  ['password', 'passwordConfirm'].includes(key)
                    ? 'password'
                    : 'text'
                "
                :disabled="isLoadingProp"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
      </b-col>

      <!-- hover line -->
      <b-col cols="12">
        <div>
          <hr />
        </div>
      </b-col>

      <!-- buttons -->
      <b-col cols="12" class="mt-1">
        <div class="d-flex justify-content-center justify-content-lg-end">
          <b-button
            variant="primary"
            class="mr-3 px-5"
            :disabled="isLoadingProp || !shouldSaveButtonEnable()"
            @click="buttonClicked('CONFIRM')"
          >
            <b-spinner small v-if="isLoadingProp"></b-spinner>
            <span v-else>ยืนยัน</span>
          </b-button>
          <b-button
            variant="outline-primary"
            class="px-5"
            :disabled="isLoadingProp"
            @click="buttonClicked('CANCEL')"
          >
            <span>ยกเลิก</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
  </app-card>
</template>

<style></style>
